
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import {
  AnimalType,
  CustomerData,
  MemberData,
  PatientData,
  PossibleReservation,
  ReservationData,
  ReservationInfo,
} from "@/@types/reservation";
import { PetSex } from "@/constants";
import FacilityModule from "@/store/facility";
import FacilityService from "@/services/facilities";
import MemberService from "@/services/members";
import { asyncForEach, deleteNoChangeReservationProp, getPhoneNumber3, getPostalCode2 } from "@/utils/Util";
import moment from "moment";
import _ from "lodash";
import Button from "@/components/Button.vue";
import FormSet from "@/components/FormSet.vue";
import FormYmd from "@/components/FormYmd.vue";

@Component({
  components: {
    Button,
    FormSet,
    FormYmd,
  },
})
export default class UpdateReservationForm extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref() datepicker: any;

  private courses = [];

  private routeName = this.$route.name;

  private loading = false;

  private reservationTimesLoading = false;

  private showLabel = false;

  private isRegisteredCustomer = false;
  private isRegisteredPatient = false;

  private get hospitalId() {
    return FacilityModule.hospitalId;
  }

  private customerPatientList = [];

  private animalTypes: AnimalType[] = [];

  private minuteIncrement = 15;

  private reservationInfo: ReservationData | null = null;

  private beforeUpdateReservation: ReservationData | null = null;
  private reservationDate = "";

  private reservationTime = "";

  private reservationTimes: string[] = [];

  private back() {
    this.$router.push({ name: "Schedule" });
  }

  private form: ReservationInfo = {
    aliasId: "",
    lastName: "",
    firstName: "",
    kanaLastName: "",
    kanaFirstName: "",
    email: "",
    phoneNumber: {
      number1: "",
      number2: "",
      number3: "",
    },
    postalCode: {
      number1: "",
      number2: "",
    },
    address: "",
    pets: [
      {
        petName: "",
        kanaPetName: "",
        petType: "",
        petSex: undefined,
        petBirthDay: {
          year: 0,
          month: undefined,
          day: undefined,
        },
        petTypeDescription: "",
        petInsurance: "",
        aliasId: "",
      },
    ],
  };

  @Watch("reservationDate")
  private async changeReservationDate() {
    this.reservationTimes = [];
    this.reservationTimesLoading = true;
    const year = String(moment(this.reservationDate).get("year"));
    const month = String(moment(this.reservationDate).get("month") + 1);
    const date = String(moment(this.reservationDate).get("date"));
    if (this.reservationInfo?.course_id) {
      const res = await FacilityService.getPossibleReservations(
        this.hospitalId,
        this.reservationInfo?.course_id,
        year,
        month
      );
      const possibleReservations = res.data.data;
      //選択した日付と同じ予約可能枠をフィルター
      asyncForEach(possibleReservations, (reservation: PossibleReservation) => {
        if (
          year == String(moment(reservation.datetime).get("year")) &&
          month == String(moment(reservation.datetime).get("month") + 1) &&
          date == String(moment(reservation.datetime).get("date")) &&
          reservation.remained_count
        ) {
          this.reservationTimes.push(moment(reservation.datetime).format("HH:mm"));
        }
      });
    }
    this.reservationTimesLoading = false;
  }

  private async getReservationDetail() {
    try {
      this.loading = true;
      const id = this.$route.params.id;
      const res = await FacilityService.getReservationDetail(this.hospitalId, id);
      this.reservationDate = res.data.data[0].datetime;
      this.reservationTime = moment(this.reservationDate).format("HH:mm");
      this.reservationInfo = _.cloneDeep(res.data.data[0]);
      this.beforeUpdateReservation = _.cloneDeep(res.data.data[0]);
      if (this.reservationInfo?.customer_id) {
        await this.getCustomerDetail();
      } else {
        await this.getMemberDetail();
      }
      if (this.reservationInfo?.patient_ids && this.reservationInfo?.patient_ids.length) {
        await this.getPatientDetail();
      } else {
        await this.getPetDetail();
      }
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async getCustomerDetail() {
    try {
      if (this.reservationInfo) {
        const res = await FacilityService.getCustomer(this.hospitalId, this.reservationInfo?.customer_id);
        const customerDetail: CustomerData = res.data.data[0];
        this.form.lastName = customerDetail.last_name;
        this.form.firstName = customerDetail.first_name;
        this.form.kanaLastName = customerDetail.last_name_kana;
        this.form.kanaFirstName = customerDetail.first_name_kana;
        if (customerDetail.email) this.form.email = customerDetail.email;
        if (customerDetail.tel) this.form.phoneNumber = getPhoneNumber3(customerDetail.tel);
        if (customerDetail.zip_code) this.form.postalCode = getPostalCode2(customerDetail.zip_code);
        if (customerDetail.address) this.form.address = customerDetail.address;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async getMemberDetail() {
    try {
      if (this.reservationInfo) {
        const res = await MemberService.getMember(this.reservationInfo?.member_id);
        const memberDetail: MemberData = res.data.data[0];
        this.form.lastName = memberDetail.last_name;
        this.form.firstName = memberDetail.first_name;
        this.form.kanaLastName = memberDetail.last_name_kana;
        this.form.kanaFirstName = memberDetail.first_name_kana;
        if (memberDetail.email) this.form.email = memberDetail.email;
        if (memberDetail.tel) this.form.phoneNumber = getPhoneNumber3(memberDetail.tel);
        if (memberDetail.zip_code) this.form.postalCode = getPostalCode2(memberDetail.zip_code);
        if (memberDetail.address) this.form.address = memberDetail.address;
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async getPatientDetail() {
    try {
      if (this.reservationInfo?.patient_id) {
        const res = await FacilityService.getPatient(this.hospitalId, this.reservationInfo?.patient_id);
        const patientDetail: PatientData = res.data.data[0];
        this.form.pets[0].aliasId = patientDetail.alias_id;
        this.form.pets[0].petName = patientDetail.name;
        this.form.pets[0].kanaPetName = patientDetail.name_kana;
        this.form.pets[0].petType = patientDetail.animal_type;
        this.form.pets[0].petTypeDescription = patientDetail.animal_breed;
        this.form.pets[0].petSex = patientDetail.gender;
        this.form.pets[0].petInsurance = patientDetail.insurance;
        if (patientDetail.birthday) {
          this.form.pets[0].petBirthDay.year = patientDetail.birthday.substr(0, 4);
          this.form.pets[0].petBirthDay.month = patientDetail.birthday.substr(4, 2);
          this.form.pets[0].petBirthDay.day = patientDetail.birthday.substr(6, 2);
        }
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }
  private async getPetDetail() {
    try {
      if (this.reservationInfo?.pet_id) {
        const res = await MemberService.getPet(this.reservationInfo?.member_id, this.reservationInfo?.pet_id);
        const petDetail: PatientData = res.data.data[0];
        this.form.pets[0].aliasId = petDetail.alias_id;
        this.form.pets[0].petName = petDetail.name;
        this.form.pets[0].kanaPetName = petDetail.name_kana;
        this.form.pets[0].petType = petDetail.animal_type;
        this.form.pets[0].petTypeDescription = petDetail.animal_breed;
        this.form.pets[0].petSex = petDetail.gender;
        this.form.pets[0].petInsurance = petDetail.insurance;
        if (petDetail.birthday) {
          this.form.pets[0].petBirthDay.year = petDetail.birthday.substr(0, 4);
          this.form.pets[0].petBirthDay.month = petDetail.birthday.substr(4, 2);
          this.form.pets[0].petBirthDay.day = petDetail.birthday.substr(6, 2);
        }
      }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private async getCourses() {
    try {
      const res = await FacilityService.getCourses(this.hospitalId);
      this.courses = res.data.data;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  // private async getCustomer() {
  //   try {
  //     const res = await FacilityService.getCustomer(this.hospitalId, this.form.aliasId)
  //   } catch (error) {

  //   }
  // }

  private async mounted() {
    this.getCourses();
    this.getAnimalTypes();
    await this.getReservationDetail();
  }

  private deletePet(index: number) {
    this.form.pets.splice(index, 1);
  }

  private showModal(name: string) {
    this.$modal.show(name);
  }

  private closeModal(name: string) {
    this.$modal.hide(name);
  }

  private async getAnimalTypes() {
    try {
      const res = await FacilityService.getAnimalTypes();
      this.animalTypes = res.data.data;
      const otherIndex = this.animalTypes.findIndex((item) => item.animal_type === "その他");
      this.animalTypes.push(this.animalTypes.splice(otherIndex, 1)[0]);
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private isEmpty(obj: any) {
    return !Object.keys(obj).length;
  }

  private async submit() {
    try {
      this.loading = true;
      if (this.reservationInfo) {
        this.reservationInfo.datetime = moment(this.reservationDate)
          .hour(Number(this.reservationTime.slice(0, 2)))
          .minute(Number(this.reservationTime.slice(3, 5)))
          .second(0)
          .format();
        const updateReservation = deleteNoChangeReservationProp(
          this.beforeUpdateReservation,
          _.cloneDeep(this.reservationInfo)
        );
        if (updateReservation.course_id) {
          updateReservation.course_id = updateReservation.course_id.id;
        }
        if (this.reservationInfo?.id && !this.isEmpty(updateReservation))
          await FacilityService.updateReservation(this.hospitalId, this.reservationInfo?.id, updateReservation);
        this.showModal("updated");
        await this.getReservationDetail();
        this.loading = false;
        this.showLabel = true;
        setTimeout(() => {
          this.showLabel = false;
        }, 5000);
      }
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private async reservationDelete() {
    try {
      this.loading = true;
      if (this.reservationInfo?.id) await FacilityService.deleteReservation(this.hospitalId, this.reservationInfo.id);
      this.$router.push({ name: "Schedule" });
      this.loading = false;
    } catch (error: any) {
      this.loading = false;
      throw new Error(error);
    }
  }

  private get petSex() {
    return PetSex;
  }
}
