import moment from "moment";

export const Pets = ["犬", "猫", "小鳥"];
export const PetSex = ["オス", "メス", "オス(去勢済み)", "メス(避妊済み)", "不明"];
export const Months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const getMonthsFromYear = function (year: string | number, pastOnly = false): string[] {
  if (pastOnly) {
    const today = new Date();
    if (year == today.getFullYear()) {
      return Months.slice(0, today.getMonth() + 1);
    }
  }
  return Months;
};

export const getDaysFromMonth = function (year: string | number, month: string | number, pastOnly = false): string[] {
  const days = Array.from({ length: moment(`${year}${month}`, "YYYYMM").daysInMonth() }, (_, k) =>
    `${k + 1}`.padStart(2, "0")
  );
  if (pastOnly) {
    const today = new Date();
    if (year == today.getFullYear() && parseInt(`${month}`) == today.getMonth() + 1) {
      return days.slice(0, today.getDate());
    }
  }
  return days;
};
