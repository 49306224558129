
import { Component } from "vue-property-decorator";
import HospitalHeader from "@/components/HospitalHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import UpdateReservationForm from "@/components/forms/UpdateReservationForm.vue";
import CheckCommonLogin from "@/utils/CheckCommonLogin";

@Component({
  name: "EditReservation",
  components: {
    UpdateReservationForm,
    HospitalHeader,
    ManagementFooter,
  },
})
export default class EditReservation extends CheckCommonLogin {}
