
import { getDaysFromMonth, getMonthsFromYear, PetSex } from "@/constants";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class FormYmd extends Vue {
  @Prop({ type: String })
  readonly year!: string;

  @Prop({ type: String })
  readonly month!: string;

  @Prop({ type: String })
  readonly day!: string;

  @Prop({ type: Boolean, default: false })
  readonly isFormDisabled?: boolean;

  get petSex(): string[] {
    return PetSex;
  }

  get years(): string[] {
    const years = [];
    for (let year = new Date().getFullYear(); year >= new Date().getFullYear() - 100; year--) {
      years.push(year.toString());
    }
    return years;
  }

  getMonths(year: string): string[] {
    return getMonthsFromYear(year, true);
  }

  getDays(year: string, month: string): string[] {
    return getDaysFromMonth(year, month, true);
  }
}
