import { UpdateUser } from "@/@types/user";
import axios from "@/plugins/axios";

class MemberService {
  getAll(): Promise<any> {
    return axios.get(`/membership/members`);
  }
  getPet(member_id: string, pet_id: string): Promise<any> {
    return axios.get(`/membership/members/${member_id}/pets/${pet_id}`);
  }
  getMember(member_id: string): Promise<any> {
    return axios.get(`/membership/members/${member_id}`);
  }
  updateMember(id: string, data: UpdateUser, is_management: string): Promise<any> {
    return axios.put(`/membership/members/${id}`, data, { params: { is_management } });
  }
  delete(id: string): Promise<any> {
    return axios.delete(`/membership/members/${id}`, { params: { is_permanent_delete: "true" } });
  }
}

export default new MemberService();
